import React, { useEffect, useState } from "react";
import SEO from "../../components/seo"
import loadable from '@loadable/component';
import LayoutTwo from '../../components/layoutTwo';
import { Container, Row, Col } from 'react-bootstrap';

const BreadcrumbModule = loadable(() => import("../../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../../components/StaticIntro/StaticIntro"));
const ValuationThankYou = loadable(() => import("../../components/ValuationThankYou/ValuationThankYou"));
const ValuationContact = loadable(() => import("../../components/ValuationContact/ValuationContact"));

const InstantValuationResults = (props) => {
    const [result, setResult] = useState(null);
    const [thankyou, setThanksyou] = useState(false);
    useEffect(() => {
        if (result === null && sessionStorage.getItem('valuationResult') != null) {
            setResult(JSON.parse(sessionStorage.getItem('valuationResult')))
        }
    })
    return (
        <LayoutTwo>
        <SEO title="Instant Valuation Results" description="Instant Valuation Results" />
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                parent="Contact"
                title="Instant Valuation Results"
            />

            <Container>
                <Row>
                    <Col lg={7}>
                        <StaticIntro
                            title="Instant Valuation"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
                            staticClass="static-intro-secondary"
                            tag="inner-large"
                            noborderTag="no-border-tag"
                        />

                        <div className="d-md-none">
                            <ValuationContact tag="inner-form" />
                        </div>

                        <ValuationThankYou {...result} />
                    </Col>

                    <Col lg={1}></Col>

                    <Col lg={4} className="d-md-block d-none">
                        <ValuationContact tag="inner-form" />
                    </Col>
                </Row>
            </Container>
        </LayoutTwo>
    )
}

export default InstantValuationResults